<template>
  <v-container>
    <v-row>
      <v-col cols="auto">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn color="success" min-width="100" @click="refreshData">
            Refresh
          </v-btn>
          <v-btn color="primary" min-width="100" @click="changeLocale">
            Locale: {{ locale }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <v-container style="max-width: 980px">
      <v-row v-for="index in 5" :key="index">
        <v-col cols="12" class="text-h4">
          {{ tabTitles[index - 1] }}
          <v-btn small icon @click="openDialogEdit(index - 1)">
            <v-icon>mdi-pencil-box-multiple-outline</v-icon>
          </v-btn>
        </v-col>
        <v-row v-if="index !== landingPageContentsType.PERIODICAL" class="mx-2">
          <v-col
            v-for="content in contents[index - 1]"
            :key="content.id"
            cols="6"
            md="3"
          >
            <v-card style="position: relative; width: 230px">
              <div style="height: 172px">
                <a
                  v-if="content.linkUrl"
                  :href="content.linkUrl"
                  target="_blank"
                  style="
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                  "
                />
                <img
                  v-if="content.imageUri"
                  :src="gopaxUrl + content.imageUri"
                  style="width: 100%; height: 100%; object-fit: fill"
                  alt="landing page contents image"
                />
                <v-avatar v-else width="100%" height="150" color="grey" tile>
                  <v-icon>mdi-camera</v-icon>
                </v-avatar>
              </div>
              <v-card-text
                v-if="index !== landingPageContentsType.MARKET_RESEARCH"
              >
                {{ content.title }}
              </v-card-text>
              <v-card-text
                v-if="index === landingPageContentsType.ALGOPAX"
                class="text-caption"
              >
                {{ content.subTitle }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-col v-else cols="8">
          <v-simple-table>
            <thead>
              <tr>
                <th id="periodical-subtitle">Sub Title</th>
                <th id="periodical-title">Title</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="content in contents[index - 1]"
                :key="content.id"
                @click="openLink(content.linkUrl)"
              >
                <td>{{ content.subTitle }}</td>
                <td>{{ content.title }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
    <!-- update form -->
    <v-dialog v-model="dialogEdit" max-width="1000px" persistants>
      <v-card>
        <v-card-title class="text-h5">
          {{ dialogTitle }}
          <div v-if="dialogTitle === 'Algopax'" class="text-caption">
            Thumbnail Download:<br />
            {{ thumbnail }}<br />
            {{ maxResThumbnail }}
          </div>
        </v-card-title>
        <v-card-text>
          <v-row v-if="dialogIndex !== landingPageContentsType.PERIODICAL">
            <v-col
              v-for="(content, idx) in updateContents"
              :key="content.id"
              cols="3"
            >
              <v-card width="230">
                <div
                  style="cursor: pointer; width: 100%; height: 172px"
                  @click="clickImageToUpload(content.id)"
                >
                  <img
                    v-if="content.imageUri"
                    :src="gopaxUrl + content.imageUri"
                    style="width: 100%; height: 100%; object-fit: fill"
                    alt="landing page contents image"
                  />
                  <v-avatar v-else width="220" height="172">
                    <v-icon>mdi-camera</v-icon>
                  </v-avatar>
                </div>
                <div class="d-none">
                  <v-file-input
                    :id="'fileInput' + content.id"
                    v-model="file"
                    accept="image/*"
                    hide-input
                    show-size
                    label="File input"
                    prepend-icon="mdi-camera"
                    @change="uploadImage(idx, content.id)"
                  ></v-file-input>
                </div>
                <v-form class="mx-1">
                  <v-text-field
                    v-if="
                      dialogIndex !== landingPageContentsType.MARKET_RESEARCH
                    "
                    v-model="content.title"
                    label="Title"
                  ></v-text-field>
                  <v-textarea
                    v-model="content.linkUrl"
                    rows="3"
                    auto-grow
                    label="Link Url"
                  ></v-textarea>
                  <v-text-field
                    v-if="content.type === landingPageContentsType.ALGOPAX"
                    v-model="content.subTitle"
                    label="Upload Date"
                    hint="yyyy-mm-dd"
                  ></v-text-field>
                  <v-text-field
                    v-model="content.displayOrder"
                    :hint="orderHint"
                    label="Order"
                  ></v-text-field>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
          <v-form v-for="content in updateContents" v-else :key="content.id">
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="content.subTitle"
                  dense
                  label="Sub Title"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="content.title"
                  dense
                  label="Title"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="content.displayOrder"
                  dense
                  :hint="orderHint"
                  label="Order"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="content.linkUrl"
                  dense
                  label="Link Url"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDialogEdit()">
            취소
          </v-btn>
          <v-btn color="green darken-1" text @click="submitUpdate()">
            수정
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" top centered>
      {{ notificationMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import constants from "./constants";
import store from "@/store";

const ViewID = constants.VIEW_LANDING_PAGE_CONTENTS;
const Actions = {
  update: "admin:LandingPageContents:Update",
};

export default {
  data: (vm) => ({
    tab: 0,
    locale: "ko",
    tabTitles: [
      "Market Research",
      "CEO Update",
      "Tech",
      "Periodical",
      "Algopax",
    ],
    contents: [[], [], [], [], []],
    permissions: [],
    file: undefined,
    updateContents: [],
    dialogEdit: false,
    dialogTitle: "",
    dialogIndex: 0,

    snackbar: false,
    notificationMessage: "",
    orderHint: vm.$i18n.t("order-hint"),
    thumbnail: "https://img.youtube.com/vi/<video_id>/0.jpg",
    maxResThumbnail: "https://img.youtube.com/vi/<video_id>/maxresdefault.jpg",

    // TODO: constants proto
    landingPageContentsType: {
      MARKET_RESEARCH: 1,
      CEO_UPDATE: 2,
      TECH: 3,
      PERIODICAL: 4,
      ALGOPAX: 5,
    },
  }),
  computed: {
    gopaxUrl() {
      return this.getGopaxHost();
    },
  },
  mounted() {
    Promise.all([this.refreshData(), this.getPermissions()]);
  },
  methods: {
    async refreshData() {
      try {
        // 백엔드 REST API를 호출해 데이터 조회
        this.contents = [[], [], [], [], []];
        const res = await this.authGet(
          `/api/landing-page?locale=${this.locale}`
        );
        console.log(res);
        res.data.data.forEach((content) => {
          this.contents[content.type - 1].push(content);
        });
      } catch (e) {
        console.error(e);
      }
    },

    async getPermissions() {
      try {
        // 백엔드에서 요청한 현재 화면에서 허용된 액션 목록
        const res = await this.authGet(`/api/auth/perm/views/${ViewID}`);
        this.permissions = res.data.data || [];

        // Store에 저장된 현재 화면에서 허용된 액션 목록
        const allowedActions = store.getters.getViewAllowedActions(ViewID);

        console.log("permissions: ", this.permissions);
        console.log("allowedActions: ", allowedActions);

        // TODO: 페이지가 마운트될 때마다 권한을 읽어올지, Store에 저장된 권한을 읽어올 지 선택 필요
      } catch (e) {
        this.processError(e);
      }
    },

    updateEnabled() {
      return this.permission.indexOf(Actions.update >= 0);
    },

    async uploadImage(idx, contentId) {
      console.log("Upload Content Image", contentId);
      try {
        const formData = new FormData();

        if (this.file) {
          console.log(typeof this.file, this.file, this.file.name);
          formData.append(`file`, this.file, this.file.name);

          console.log(
            "before upload,",
            typeof formData,
            formData.getAll("file")
          );
          const res = await this.authPostWithFiles(
            `/api/landing-page/${contentId}`,
            formData
          );

          console.log("after upload,", res.data);
          if (res.data.result) {
            const { key } = JSON.parse(res.data.data);
            console.log(key);
            this.$set(this.updateContents[idx], "imageUri", key);
          }
        } else {
          delete this.updateContents[idx].imageUri;
        }
      } catch (e) {
        console.error(e);
        this.processError(e);
      }
    },

    async submitUpdate() {
      try {
        const formData = { data: this.updateContents };
        console.log("before update", formData);
        const res = await this.authPut(`/api/landing-page`, formData);
        console.log("after update,", res);
        this.refreshData();
        this.dialogEdit = false;
      } catch (e) {
        console.error(e);
        this.dialogEdit = false;
        this.processError(e);
      }
    },

    processError(e) {
      const { response } = e;
      if (response) {
        this.showNotification(response.data);
      } else {
        console.error(e);
        this.showNotification(e);
      }
    },

    showNotification(msg) {
      this.notificationMessage = msg;
      this.snackbar = true;
    },

    openDialogEdit(index) {
      this.updateContents = JSON.parse(JSON.stringify(this.contents[index]));
      this.dialogEdit = true;
      this.dialogTitle = this.tabTitles[index];
      this.dialogIndex = index + 1; // start from 1
      console.log(this.updateContents);
    },

    closeDialogEdit() {
      this.dialogEdit = false;
      this.updateContents = [];
    },

    clickImageToUpload(contentId) {
      document.getElementById(`fileInput${contentId}`).click();
    },

    openLink(url) {
      window.open(url, "_blank");
    },

    changeLocale() {
      this.locale = this.locale === "ko" ? "en" : "ko";
      this.refreshData();
    },
  },
};
</script>
